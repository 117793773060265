import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const InteranationalBusinessAndNonResidentSupport = () => (
  <Layout>
    <SEO title="International Business & Non-Resident Support" />
    <div className="container primary-bg">
      <div className="row">
        <div className="col">
          <h1 className="small-padding">International Business & Non-Resident Support</h1>
          <p className="small-padding">
            Miami's business community is one of the most diverse in the
            country. As such we want to make sure foreign owned companies have
            access to unique resources. Please find a list composed of active
            bi-national chambers of commerce and trade and investment promotion
            agencies located in Miami-Dade.
          </p>
          <Link className="full-cta" to="/immigration-attorneys">
            Immigration Attorney
          </Link>
          <Link className="full-cta" to="/specialized-services">
            Specialized Services
          </Link>
        </div>
      </div>
    </div>
  </Layout>
)

export default InteranationalBusinessAndNonResidentSupport
